import axios from 'axios'
import router from '@/router'
import api from '@/api'
import { sortByKey, toISODateUTC } from '@/functions'

const data = {
  data: {
    created_at: '',
    created_by: '',
    updated_at: '',
    ownerId: '',
    id: '',
    name: '',
    description: '',
    report: '',
    ids: [],
    definition: {
      output: [],
      filters: [],
      workspaces: []
    },
    schedule: {
      interval: {
        periodType: '',
        interval: 0
      },
      recipients: [],
      from: null,
      to: null
    },
    forceReport: false
  },
  custom: {
    availableColumns: [],
    availableReports: [],
    generatedReport: {
      data: [],
      labels: []
    },
    scheduled: []
  },
  default: ''
}

const ebsReportBuilder = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEbsReportBuilderId: state => {
      return state.data.id
    },
    getEbsReportBuilderUpdatedAt: state => {
      return state.data.updated_at
    },
    getEbsReportBuilderDefinition: state => {
      return state.data.definition
    },
    getEbsReportBuilderName: state => {
      return state.data.name
    },
    getEbsReportBuilderDescription: state => {
      return state.data.description
    },
    getEbsReportBuilderReport: state => {
      return state.data.report
    },
    getEbsReportBuilderIds: state => {
      return state.data.ids
    },
    getEbsReportBuilderOutput: state => {
      return state.data.definition.output
    },
    getEbsReportBuilderAvailableColumns: state => {
      return state.custom.availableColumns
    },
    getEbsReportBuilderAvailableReports: state => {
      const reports = state.custom.availableReports
      let reportsArr = []

      for (const [key, value] of Object.entries(reports)) {
        reportsArr.push({
          name: value,
          value: key
        })
      }

      return reportsArr
    },
    getEbsReportBuilderAvailableColumnsMappedLabelValue: state => {
      const columns = state.custom.availableColumns
      const columnsArrMapped = []

      for (const [, value] of Object.entries(columns)) {
        columnsArrMapped.push({
          label: value.name,
          value: value.key,
          node: value.node,
          filters: value.filters,
          dataType: value.dataType,
          category: value.category,
          draggable: value.draggable,
          availableValues: value.availableValues
        })
      }

      return columnsArrMapped
    },
    getEbsReportBuilderFilters: state => {
      return state.data.definition.filters
    },
    getEbsReportBuilderFiltersSpecificValue: state => column => {
      const filters = state.data.definition.filters
      const specificFilter = filters.find(obj => obj.column === column)

      return specificFilter ? specificFilter.threshold.value : ''
    },
    getEbsReportBuilderGeneratedReport: state => {
      return state.custom.generatedReport
    },
    getEbsReportBuilderScheduleIntervalPeriodType: state => {
      return (state.data.schedule.interval ? state.data.schedule.interval.periodType : 'never') || 'never'
    },
    getEbsReportBuilderScheduleIntervalPeriodTypeDefault: state => {
      return state.default && state.default.schedule && state.default.schedule.interval ? state.default.schedule.interval.periodType : ''
    },
    getEbsReportBuilderScheduleIntervalInterval: state => {
      return state.data.schedule.interval.interval
    },
    getEbsReportBuilderScheduleFrom: state => {
      return state.data.schedule.from
    },
    getEbsReportBuilderScheduleTo: state => {
      return state.data.schedule.to
    },
    getEbsReportBuilderScheduleRecipients: state => {
      return state.data.schedule.recipients
    },
    getEbsReportBuilderCustomScheduled: state => {
      return sortByKey(state.custom.scheduled, 'plannedExecutionDate', 'desc', 'date')
    },
    getEbsReportBuilderForceReport: state => {
      return state.data.forceReport
    }
  },
  mutations: {
    EBS_REPORT_BUILDER_SET_NAME (state, payload) {
      state.data.name = payload
    },
    EBS_REPORT_BUILDER_SET_DESCRIPTION (state, payload) {
      state.data.description = payload
    },
    EBS_REPORT_BUILDER_SET_REPORT (state, payload) {
      state.data.report = payload
    },
    EBS_REPORT_BUILDER_SET_IDS (state, payload) {
      state.data.ids = payload.map(obj => obj.value)
    },
    EBS_REPORT_BUILDER_SET_AVAILABLE_COLUMNS (state, payload) {
      state.custom.availableColumns = payload
    },
    EBS_REPORT_BUILDER_FIX_DEFINITION_OUTPUT (state, payload) {
      const availableColumns = payload
      const currentOutput = state.data.definition.output

      const filteredOutput = currentOutput.filter(value => availableColumns.findIndex(item => item.key === value) > -1)

      state.data.definition.output = filteredOutput
    },
    EBS_REPORT_BUILDER_SET_AVAILABLE_REPORTS (state, payload) {
      state.custom.availableReports = payload
    },
    EBS_REPORT_BUILDER_SET_COLUMNS (state, payload) {
      let items = []
      for (let i = 0; i < payload.length; i++) {
        items.push(payload[i].value)
      }
      state.data.definition.output = items
    },
    EBS_REPORT_BUILDER_SET_FILTERS (state, payload) {
      let items = []
      for (let i = 0; i < payload.length; i++) {
        items.push(payload[i].value)
      }
      state.data.definition.filters = items
    },
    EBS_REPORT_BUILDER_CLEAR (state) {
      state.data = JSON.parse(JSON.stringify(data.data))
      state.default = JSON.parse(JSON.stringify(data.default))
      state.custom = JSON.parse(JSON.stringify(data.custom))
    },
    EBS_REPORT_BUILDER_RESET (state) {
      state.data = JSON.parse(JSON.stringify(data.default))
    },
    EBS_REPORT_BUILDER_COLUMNS_SET (state, payload) {
      state.data.definition.output = payload
    },
    EBS_REPORT_BUILDER_COLUMNS_ADD (state, payload) {
      let output = state.data.definition.output
      const outputQAIndex = output ? output.findIndex(obj => obj.includes('QuestionsAndAnswers')) : -1

      output.push(payload)

      if (outputQAIndex > -1) {
        output.push(output.splice(outputQAIndex, 1)[0])
      }
    },
    EBS_REPORT_BUILDER_COLUMNS_REMOVE (state, payload) {
      state.data.definition.output.splice(payload, 1)
    },
    EBS_REPORT_BUILDER_COLUMNS_MOVE (state, payload) {
      const output = state.data.definition.output
      const item = output[payload.from]
      if (payload.to !== -1) {
        output.splice(payload.from, 1)
        output.splice(payload.to, 0, item)
      }
      const outputQAIndex = output ? output.findIndex(obj => obj.includes('QuestionsAndAnswers')) : -1
      if (outputQAIndex > -1) {
        output.push(output.splice(outputQAIndex, 1)[0])
      }
    },
    EBS_REPORT_BUILDER_COLUMNS_CLEAR (state) {
      state.data.definition.output = []
    },
    EBS_REPORT_BUILDER_FILTERS_ADD (state, payload) {
      state.data.definition.filters.push(payload)
    },
    EBS_REPORT_BUILDER_FILTERS_SET_SPECIFIC (state, payload) {
      const column = payload.column
      const threshold = payload.threshold
      let filters = state.data.definition.filters
      const filtersColumnIndex = filters.findIndex(obj => obj.column === column)

      if (filtersColumnIndex > -1) {
        filters[filtersColumnIndex].threshold = threshold
      } else {
        filters.push(payload)
      }
    },
    EBS_REPORT_BUILDER_FILTERS_REMOVE (state, payload) {
      if (payload.column && payload.value && payload.type) {
        const filterIndex = state.data.definition.filters.findIndex(obj => obj.column === payload.column && (obj.threshold.value === payload.value || obj.threshold.name === payload.value) && obj.type === payload.type)

        state.data.definition.filters.splice(filterIndex, 1)
      } else {
        state.data.definition.filters = state.data.definition.filters.filter(obj => obj.column !== payload.column)
      }
    },
    EBS_REPORT_BUILDER_FILTERS_CLEAR (state) {
      state.data.definition.filters = []
    },
    EBS_REPORT_BUILDER_SET_DEFINED_REPORT (state, payload) {
      let payloadData = JSON.parse(JSON.stringify(payload))
      const outputQAIndex = payloadData.definition && payloadData.definition.output ? payloadData.definition.output.findIndex(obj => obj.includes('QuestionsAndAnswers')) : -1

      if (payloadData.schedule) {
        payloadData.schedule = payloadData.schedule.scheduleDefinition
        payloadData.schedule.from = payloadData.schedule.from ? toISODateUTC(payloadData.schedule.from) : null
        payloadData.schedule.to = payloadData.schedule.to ? toISODateUTC(payloadData.schedule.to) : null
      } else {
        payloadData.schedule = state.data.schedule
      }
      if (outputQAIndex > -1) {
        payloadData.definition.output.push(payloadData.definition.output.splice(outputQAIndex, 1)[0])
      }

      payloadData.forceReport = false

      state.data = JSON.parse(JSON.stringify(payloadData))
      state.default = JSON.parse(JSON.stringify(payloadData))
    },
    EBS_REPORT_BUILDER_SET_DEFINED_REPORT_SCHEDULED (state, payload) {
      state.custom.scheduled = payload
    },
    EBS_REPORT_BUILDER_SET_GENERATED_REPORT (state, payload) {
      let dataPayload = payload.data.splice(0, 500)
      let dataArr = dataPayload.map(obj => {
        let dataItemArr = []
        const dataItemEntries = Object.entries(obj)

        for (const [, value] of dataItemEntries) {
          dataItemArr.push(value)
        }
        return dataItemArr
      })

      state.custom.generatedReport.data = dataArr
      state.custom.generatedReport.labels = payload.headers
    },
    EBS_REPORT_BUILDER_SET_SCHEDULE_INTERVAL_PERIODTYPE (state, payload) {
      state.data.schedule.interval.periodType = payload
    },
    EBS_REPORT_BUILDER_SET_SCHEDULE_INTERVAL_INTERVAL (state, payload) {
      state.data.schedule.interval.interval = payload
    },
    EBS_REPORT_BUILDER_SET_SCHEDULE_FROM (state, payload) {
      state.data.schedule.from = payload
    },
    EBS_REPORT_BUILDER_SET_SCHEDULE_TO (state, payload) {
      state.data.schedule.to = payload
    },
    EBS_REPORT_BUILDER_SCHEDULE_RECIPIENTS_REMOVE (state, payload) {
      state.data.schedule.recipients.splice(payload, 1)
    },
    EBS_REPORT_BUILDER_SCHEDULE_RECIPIENTS_ADD (state, payload) {
      state.data.schedule.recipients.push(payload)
    },
    EBS_REPORT_BUILDER_SET_FORCEREPORT (state, payload) {
      state.data.forceReport = payload
    }
  },
  actions: {
    ebsReportBuilderCreateAndExecute ({ state, dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const execute = data && data.execute !== undefined ? data.execute : true
        const stateData = JSON.parse(JSON.stringify(state.data))
        const workspaceOrgUnits = getters.getLmsSuperadminOrgUnitsMappedIdName

        for (let index in stateData.definition.filters) {
          let filter = stateData.definition.filters[index]

          if (filter.column.includes('LearnerOrganisationUnit')) {
            const filterValue = filter.threshold.value
            const extendedOrgUnit = workspaceOrgUnits.find(obj => obj.id === filterValue)

            if (extendedOrgUnit) {
              filter.threshold.value = extendedOrgUnit.id
            } else {
              stateData.definition.filters.splice(index, 1)
            }
          }
        }

        stateData.definition.workspaces = [getters.getAuthUserSecondStageTokenDataWorkspaceId, getters.getAuthUserSecondStageTokenDataCustomerId]
        axios({
          method: 'post',
          url: `${api.reportengine}/report`,
          data: {
            name: stateData.name,
            description: stateData.description,
            definition: stateData.definition,
            report: stateData.report,
            ids: stateData.ids,
            schedule: stateData.schedule.interval.periodType ? stateData.schedule : undefined,
            forceReport: stateData.forceReport
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report created',
            message: 'Report has been successfully created.',
            status: 'success'
          })
          if (execute) {
            router.push({ name: 'ebs-report-builder-execute', params: { id: response.data.data.id } })
          } else {
            router.push({ name: 'ebs-report-list' })
          }

          resolve(response.data.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report created',
            message: `Report hasn't been created.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsReportBuilderUpdateAndExecute ({ state, dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        const execute = data && data.execute !== undefined ? data.execute : true
        const id = data ? data.id : undefined
        const allowRedirect = data && data.allowRedirect !== undefined ? data.allowRedirect : true
        const stateData = JSON.parse(JSON.stringify(state.data))
        const workspaceOrgUnits = getters.getLmsSuperadminOrgUnitsMappedIdName

        for (let index in stateData.definition.filters) {
          let filter = stateData.definition.filters[index]

          if (filter.column.includes('LearnerOrganisationUnit')) {
            const filterValue = filter.threshold.value
            const extendedOrgUnit = workspaceOrgUnits.find(obj => obj.id === filterValue)

            if (extendedOrgUnit) {
              filter.threshold.value = extendedOrgUnit.id
            } else {
              stateData.definition.filters.splice(index, 1)
            }
          }
        }

        axios({
          method: 'patch',
          url: `${api.reportengine}/report/${id}`,
          data: {
            name: stateData.name,
            description: stateData.description,
            definition: stateData.definition,
            schedule: stateData.schedule.interval.periodType ? stateData.schedule : null,
            forceReport: stateData.forceReport
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report update',
            message: 'Report has been successfully updated.',
            status: 'success'
          })
          if (allowRedirect) {
            if (execute) {
              router.push({ name: 'ebs-report-builder-execute', params: { id: response.data.id } })
            } else {
              router.push({ name: 'ebs-report-list' })
            }
          }

          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report update',
            message: `Report hasn't been updated.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsReportBuilderRemove ({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'delete',
          url: `${api.reportengine}/report/${id}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report removed',
            message: 'Report has been successfully removed.',
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report removed',
            message: `Report hasn't been removed.`,
            status: 'error'
          })
          reject(error)
        })
      })
    },
    ebsReportBuilderGetDefinedReport ({ commit, dispatch, getters }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/report/${id}`
        }).then(response => {
          let responseData = JSON.parse(JSON.stringify(response.data))
          let responseDataFilters = responseData.definition.filters

          if (responseDataFilters.find(obj => obj.column.includes('LearnerOrganisationUnit'))) {
            const ownerId = responseData.ownerId

            dispatch('lmsSuperadminOrgUnitsGet', ownerId).then(() => {
              const workspaceOrgUnits = getters.getLmsSuperadminOrgUnitsMappedIdName

              for (let index in responseData.definition.filters) {
                let filter = responseData.definition.filters[index]

                if (filter.column.includes('LearnerOrganisationUnit')) {
                  const filterValue = filter.threshold.value
                  const extendedOrgUnit = workspaceOrgUnits.find(obj => obj.id === filterValue)

                  if (extendedOrgUnit) {
                    filter.threshold.value = extendedOrgUnit.id
                  } else {
                    responseData.definition.filters.splice(index, 1)
                  }
                }
              }

              commit('EBS_REPORT_BUILDER_SET_DEFINED_REPORT', responseData)
              resolve(responseData)
            })
          } else {
            commit('EBS_REPORT_BUILDER_SET_DEFINED_REPORT', responseData)
            resolve(responseData)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderGetDefinedReportScheduled ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/report/${id}/schedule`
        }).then(response => {
          commit('EBS_REPORT_BUILDER_SET_DEFINED_REPORT_SCHEDULED', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderExecuteDefinedReport ({ commit, dispatch }, reportId) {
      return new Promise((resolve, reject) => {
        commit('EBS_REPORT_BUILDER_CLEAR')
        const ebsReportBuilderGetDefinedReport = () => {
          return dispatch('ebsReportBuilderGetDefinedReport', reportId)
        }

        ebsReportBuilderGetDefinedReport().then(response => {
          axios({
            method: 'post',
            url: `${api.reportengine}/builder/execute`,
            data: {
              output: response.definition.output,
              filters: response.definition.filters
            }
          }).then(response => {
            commit('EBS_REPORT_BUILDER_SET_GENERATED_REPORT', response.data)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    ebsReportBuilderGenerateDefinedReportCSV ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const reportId = data.id
        const reportName = data.name
        const ebsReportBuilderGetDefinedReport = () => {
          return dispatch('ebsReportBuilderGetDefinedReport', reportId)
        }
        const ebsReportBuilderExecuteCSV = (definition) => {
          return dispatch('ebsReportBuilderExecuteCSV', { definition, reportName })
        }

        ebsReportBuilderGetDefinedReport().then(response => {
          ebsReportBuilderExecuteCSV(response.definition).then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    ebsReportBuilderExecuteCSV ({ getters }, data) {
      const definition = data && data.definition ? data.definition : getters.getEbsReportBuilderDefinition
      const reportName = data && data.reportName ? data.reportName : getters.getEbsReportBuilderName

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${api.reportengine}/builder/execute/csv`,
          data: {
            output: definition.output,
            filters: definition.filters
          }
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${reportName}.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${reportName}`)
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderExecuteScheduledReport ({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        commit('EBS_REPORT_BUILDER_CLEAR')

        const reportId = data.reportId
        const resultId = data.resultId

        const ebsReportBuilderGetDefinedReport = () => {
          return dispatch('ebsReportBuilderGetDefinedReport', reportId)
        }

        ebsReportBuilderGetDefinedReport().then(() => {
          axios({
            method: 'get',
            url: `${api.reportengine}/report/${reportId}/result/${resultId}/json`
          }).then(response => {
            commit('EBS_REPORT_BUILDER_SET_GENERATED_REPORT', response.data)
            resolve(response.data)
          }).catch(error => {
            reject(error)
          })
        })
      })
    },
    ebsReportBuilderBuilderSetGeneratedReport ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_SET_GENERATED_REPORT', data)
    },
    ebsReportBuilderGenerateScheduledReportCSV (ctx, data) {
      return new Promise((resolve, reject) => {
        const reportId = data.reportId
        const reportName = data.reportName
        const resultId = data.resultId

        axios({
          method: 'get',
          url: `${api.reportengine}/report/${reportId}/result/${resultId}/csv`
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${reportName}.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${reportName}`)
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderGenerateReportResultCSV (ctx, data) {
      return new Promise((resolve, reject) => {
        const reportId = data.reportId
        const reportName = 'report-result'
        const resultId = data.resultId
        const reportEngineUrl = api.reportengine.replace('/api', '')

        axios({
          method: 'get',
          url: `${reportEngineUrl}/report/${reportId}/result/${resultId}`
        }).then(response => {
          if (!window.navigator.msSaveOrOpenBlob) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${reportName}.csv`)
            document.body.appendChild(link)
            link.click()
          } else {
            window.navigator.msSaveOrOpenBlob(new Blob([response.data]), `${reportName}`)
          }
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    async ebsReportBuilderGetAvailableColumns ({ commit }, value) {
      const { default: fileData } = await import(`@/data/reports/${value}.js`)

      return new Promise((resolve, reject) => {

        axios({
          method: 'get',
          url: `${api.reportengine}/builder/${value}/columns`
        }).then(response => {
          const customFieldsColumns = Object.values(response.data).filter(column => column.key.includes('LearnerCustomFields')).map(
            column => {
              return {
                ...column,
                category: 'Learner information',
              }
            }
          );

          let updatedFileData = [...fileData];
          const beforeCustomFieldsIndex = fileData.findIndex(column => column.beforeCustomFields);

          if (beforeCustomFieldsIndex > -1) {
            updatedFileData.splice(beforeCustomFieldsIndex + 1, 0, ...customFieldsColumns);
          } else {
            updatedFileData = [...fileData, ...customFieldsColumns];
          }

          commit('EBS_REPORT_BUILDER_SET_AVAILABLE_COLUMNS', updatedFileData);
          commit('EBS_REPORT_BUILDER_FIX_DEFINITION_OUTPUT', updatedFileData);
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderGetAvailableReports ({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: `${api.reportengine}/builder/reports`
        }).then(response => {
          commit('EBS_REPORT_BUILDER_SET_AVAILABLE_REPORTS', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    ebsReportBuilderSetName ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_NAME', value)
    },
    ebsReportBuilderSetDescription ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_DESCRIPTION', value)
    },
    ebsReportBuilderSetReport ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_REPORT', value)
    },
    ebsReportBuilderSetIds ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_IDS', value)
    },
    ebsReportBuilderSetColumns ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_COLUMNS', value)
    },
    ebsReportBuilderSetFilters ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_FILTERS', value)
    },
    ebsReportBuilderSetScheduleIntervalPeriodType ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_SCHEDULE_INTERVAL_PERIODTYPE', value)
    },
    ebsReportBuilderSetScheduleIntervalInterval ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_SCHEDULE_INTERVAL_INTERVAL', value)
    },
    ebsReportBuilderSetScheduleFrom ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_SCHEDULE_FROM', value)
    },
    ebsReportBuilderSetScheduleTo ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_SCHEDULE_TO', value)
    },
    ebsReportBuilderSetForceReport ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_SET_FORCEREPORT', value)
    },
    ebsReportBuilderScheduleRecipientsRemove ({ commit, dispatch }) {
      commit('EBS_REPORT_BUILDER_SCHEDULE_RECIPIENTS_REMOVE')
      dispatch('defaultNotificationToastsAdd', {
        title: 'Recipient removed',
        message: ``,
        status: 'success'
      })
    },
    ebsReportBuilderScheduleRecipientsAdd ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_SCHEDULE_RECIPIENTS_ADD', data)
    },
    ebsReportBuilderClear ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_CLEAR', value)
    },
    ebsReportBuilderReset ({ commit }, value) {
      commit('EBS_REPORT_BUILDER_RESET', value)
    },
    ebsReportBuilderColumnsAdd ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_COLUMNS_ADD', data)
    },
    ebsReportBuilderColumnsRemove ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_COLUMNS_REMOVE', data)
    },
    ebsReportBuilderColumnsSet ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_COLUMNS_SET', data)
    },
    ebsReportBuilderColumnsClear ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_COLUMNS_CLEAR', data)
    },
    ebsReportBuilderFiltersAdd ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_FILTERS_ADD', data)
    },
    ebsReportBuilderFiltersSetSpecific ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_FILTERS_SET_SPECIFIC', data)
    },
    ebsReportBuilderFiltersRemove ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_FILTERS_REMOVE', data)
    },
    ebsReportBuilderFiltersClear ({ commit }, data) {
      commit('EBS_REPORT_BUILDER_FILTERS_CLEAR', data)
    },
    ebsReportBuilderSendTest ({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        const reportId = data

        if (!reportId) return reject(new Error('noReportId'))

        axios({
          method: 'post',
          url: `${api.reportengine}/report-test/${reportId}`
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report test has been sent',
            status: 'success'
          })
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Report test not sent',
            status: 'error'
          })
          reject(error)
        })
      })
    }
  }
}

export default ebsReportBuilder
