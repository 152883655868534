<template>
    <transition name="fade" mode="out-in">
        <div
            :class="[
                'pop',
                'pop__notify',
                size ? `pop__notify--${size}` : '',
                alwaysVisible ? 'pop__notify--always-visible' : '',
                overlay ? 'pop__notify--overlay' : '',
                `has-text-${align}`
            ]"
            v-if="isActive || alwaysVisible"
        >
            <div class="pop__notify_overlay"></div>
            <div class="pop__notify_box">
                <template v-if="loading">
                    <component-loading></component-loading>
                </template>
                <template v-else>
                    <header class="pop__notify_box_header">
                        <template v-if="titleText">
                            <h4 class="pop__notify_box_header_title">{{titleText}}</h4>
                            <template v-if="subtitleText">
                                <p class="pop__notify_box_header_title_subtitle">{{subtitleText}}</p>
                            </template>
                        </template>
                        <template v-if="closeButtonActive">
                            <div class="pop__notify_box_header_close">
                                <component-button
                                    v-if="!alwaysVisible"
                                    :name="'pop-notify-close'"
                                    :icon="true"
                                    :icon-name="'x'"
                                    :icon-raw="true"
                                    :theme="''"
                                    @click="closeNotify"
                                ></component-button>
                            </div>
                        </template>
                    </header>
                    <div class="pop__notify_box_main">
                        <template v-if="icon">
                            <div class="pop__notify_box_main_icon">
                                <v-icon :name="iconName"></v-icon>
                            </div>
                        </template>
                        <template v-if="contentText">
                            <div class="pop__notify_box_main_content">{{contentText}}</div>
                        </template>
                        <div v-if="$slots.default" class="pop__notify_box_main_slot">
                            <template v-if="slotHtml">
                                <div v-html="slotContent"></div>
                            </template>
                            <template v-else>
                                <slot></slot>
                            </template>
                        </div>
                        <template v-if="selectActive">
                            <div class="pop__notify_box_main_select">
                                <div class="pop__notify_box_main_select_title">{{ selectTitle }}<span
                                    class="asterisk">*</span></div>
                                <div class="pop__notify_box_main_select_field">
                                    <form-select
                                        :label="''"
                                        :name="'notify-select'"
                                        :value="selectValue"
                                        :items="newSelectItems"
                                        :v-validate="'required'"
                                        :searchable="false"
                                        :is-type="'select'"
                                        :data-vv-as="'reason'"
                                        :placeholder="$t('shared.pop.labels.chooseReason')"
                                        :allow-other-field="true"
                                        @input="updateSelectValue"
                                    />
                                </div>
                                <template v-if="selectHint">
                                    <div class="pop__notify_box_main_select_alert">
                                        <component-note :danger="true">{{ selectHint }}</component-note>
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-if="formActive">
                            <component-customform
                                :fieldset="formValues"
                                @input="updateFormValue"
                            />
                        </template>
                    </div>
                    <template v-if="cancelButtonActive || customButtonActive || submitButtonActive">
                        <footer class="pop__notify_box_footer has-text-centered-mobile">
                            <div class="pop__notify_box_footer_actions">
                                <div :class="['columns', `is-${align}`]">
                                    <div class="column"></div>
                                    <template v-if="cancelButtonActive">
                                        <div class="column column--sub is-narrow">
                                            <component-button
                                                :theme="cancelButtonTheme"
                                                :border="true"
                                                @click="redirectTo ? redirect() : closeNotify()"
                                                :name="'pop-notify-box-footer-actions-cancel'"
                                            >{{cancelButtonText}}
                                            </component-button>
                                        </div>
                                    </template>
                                    <template v-if="customButtonActive">
                                        <div class="column column--sub is-narrow">
                                            <component-button
                                                :danger="customButtonDanger"
                                                :animate-loading="preventClose && customButtonAnimateLoading"
                                                :theme="customButtonTheme"
                                                @click="custom"
                                                :name="'pop-notify-box-footer-actions-custom'"
                                            >{{customButtonText}}
                                            </component-button>
                                        </div>
                                    </template>
                                    <template v-if="submitButtonActive">
                                        <div class="column column--sub is-narrow">
                                            <component-button
                                                :disabled="submitButtonDisabled"
                                                :danger="submitButtonDanger"
                                                :animate-loading="preventClose && submitButtonAnimateLoading"
                                                :animate-loading-event-name="submitButtonAnimateLoadingName"
                                                :icon="submitButtonIcon"
                                                :icon-name="submitButtonIconName"
                                                :icon-align="submitButtonIconAlign"
                                                :name="'pop-notify-box-footer-actions-submit'"
                                                @click="submit"
                                            >{{submitButtonText}}
                                            </component-button>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </footer>
                    </template>
                </template>
            </div>
        </div>
    </transition>
</template>

<script>
import ComponentNote from '@/components/default/shared/ComponentNote'
import FormSelect from '@/components/default/forms/FormSelect'
import ComponentCustomform from '@/components/default/shared/ComponentCustomform'
import { getDomPath } from '@/functions'
import ComponentLoading from '@/components/default/shared/ComponentLoading'

export default {
  components: {
    ComponentLoading,
    ComponentButton: () => import('@/components/default/shared/ComponentButton'),
    ComponentCustomform,
    FormSelect,
    ComponentNote
  },
  name: 'pop-notify',
  data () {
    return {
      isActive: false,
      isClosed: false,
      storeValue: '',
      customRedirectTo: '',
      customValue: '',
      selectOtherValue: ''
    }
  },
  props: {
    align: {
      type: String,
      default: 'left'
    },
    titleText: {
      type: String,
      default () {
        return 'Unsaved changes will be lost'
      }
    },
    subtitleText: {
      type: String
    },
    closeButtonActive: {
      type: Boolean,
      default: true
    },
    contentText: {
      type: String,
      default () {
        return this.$t('shared.pop.props.DoYouWantToContinue')
      }
    },
    customButtonText: {
      type: String,
      default () {
        return this.$t('shared.pop.props.custom')
      }
    },
    customButtonActive: {
      type: Boolean,
      default: false
    },
    customButtonTheme: {
      type: String,
      default: 'primary'
    },
    customButtonDanger: {
      type: Boolean,
      default: false
    },
    customButtonAnimateLoading: {
      type: Boolean,
      default: true
    },
    submitButtonText: {
      type: String,
      default () {
        return 'Leave without saving'
      }
    },
    submitButtonActive: {
      type: Boolean,
      default: true
    },
    submitButtonDanger: {
      type: Boolean,
      default: false
    },
    submitButtonDisabled: {
      type: Boolean,
      default: false
    },
    submitButtonAnimateLoading: {
      type: Boolean,
      default: true
    },
    submitButtonAnimateLoadingName: {
      type: String,
      default: 'submit-notify'
    },
    submitButtonIcon: {
      type: Boolean,
      default: false
    },
    submitButtonIconName: {
      type: String,
      default: ''
    },
    submitButtonIconAlign: {
      type: String
    },
    cancelButtonText: {
      type: String,
      default () {
        return 'Stay and save'
      }
    },
    cancelButtonActive: {
      type: Boolean,
      default: true
    },
    cancelButtonTheme: {
      type: String,
      default: 'default'
    },
    //
    selectValue: {
      default: ''
    },
    selectActive: {
      type: Boolean,
      default: false
    },
    selectTitle: {
      type: String,
      default: ''
    },
    selectItems: {
      type: Array,
      default: () => {
      }
    },
    selectItemsOther: {
      type: Boolean,
      default: false
    },
    selectHint: {
      type: String,
      default: ''
    },
    //
    formActive: {
      type: Boolean,
      default: false
    },
    formFields: {
      type: [Array, Object],
      default: () => {
      }
    },
    //
    redirectTo: {
      type: String
    },
    //
    preventClose: {
      type: Boolean,
      default: false
    },
    overlayPreventClose: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['small', 'normal', 'medium', 'big', 'wide', 'full'].indexOf(value) !== -1
      }
    },
    alwaysVisible: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String
    },
    slotFormActive: {
      type: Boolean,
      default: false
    },
    slotHtml: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formValues () {
      return this.formFields
    },
    newSelectItems () {
      const arr = this.selectItems
      if (this.selectItemsOther) {
        arr.push({ label: 'Other', value: 'other' })
      }
      return arr
    },
    slotContent () {
      return this.$slots.default ? this.$slots.default[0].text : ''
    }
  },
  watch: {
    isActive (val) {
      if (val) {
        window.addEventListener('keydown', this.handleKeydown)
      } else {
        window.removeEventListener('keydown', this.handleKeydown)
      }
    }
  },
  methods: {
    setStoreValue (value) {
      this.storeValue = value
    },
    closeNotify () {
      this.isActive = false
      this.$emit('emit-close')
    },
    openNotify () {
      this.isActive = true
    },
    //
    updateSelectValue: function (value) {
      this.$emit('emit-select', value)
    },
    updateFormValue: function (data) {
      this.$emit('emit-form', data)
    },
    //
    redirect () {
      this.$router.push({ name: this.redirectTo })
    },
    custom () {
      this.$emit('emit-custom')
      if (this.preventClose) {
        return
      }
      this.closeNotify()
      this.isClosed = true
    },
    submit () {
      if (this.selectActive || this.formActive || this.slotFormActive) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (this.formValues) {
              this.$emit('emit-submit', this.formValues)
            } else {
              this.$emit('emit-submit', this.selectValue)
            }
            if (this.preventClose) {
              return
            }
            this.closeNotify()
            this.isClosed = true
          } else {
            this.$eventBus.$emit(`stop-${this.submitButtonAnimateLoadingName}`)
          }
        })
      } else {
        this.$emit('emit-submit', this.customRedirectTo ? this.customRedirectTo : this.customValue)
        if (this.preventClose) {
          return
        }
        this.closeNotify()
        this.isClosed = true
      }
    },
    handleKeydown (event) {
      if (event.key === 'Enter') {
        this.submit()
      }
    },
  },
  updated () {
    let self = this
    let overlay = document.getElementsByClassName('pop__notify_overlay')
    for (let i = 0; i < overlay.length; i++) {
      overlay[i].addEventListener('click', function () {
        if (!self.overlayPreventClose) {
          self.closeNotify()
        }
      })
    }
  },
  mounted () {
    const domItem = this.$el
    const domPath = getDomPath(domItem)

    for (let i in domPath) {
      const domLoopItem = domPath[i]
      if (domLoopItem.classList && domLoopItem.classList.contains('pop')) {
        domLoopItem.append(domItem)
        break
      }
    }
  },
  provide () {
    return {
      $validator: this.$validator
    }
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/shared/popnotify";
</style>
