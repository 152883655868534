<template>
    <div class="filter__actions" v-if="primaryButtonActive || secondaryButtonActive">
        <div class="columns is-flex-mobile">
            <template v-if="secondaryButtonActive">
                <div class="column is-narrow-touch">
                    <component-button
                        :disabled="secondaryButtonDisabled"
                        :theme="'default'"
                        :border="true"
                        :animate-loading="secondaryButtonAnimateLoading"
                        :name="'filter-actions-secondary'"
                        @click="$emit('emit-secondary')"
                    >{{ secondaryButtonText }}</component-button>
                </div>
            </template>
            <template v-if="primaryButtonActive">
                <div class="column is-narrow-touch">
                    <component-button
                        :disabled="primaryButtonDisabled"
                        :animate-loading="primaryButtonAnimateLoading"
                        :name="'filter-actions-primary'"
                        @click="$emit('emit-primary')"
                    >{{ primaryButtonText }}</component-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
  name: 'filter-actions',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    secondaryButtonText: {
      type: String,
      default: 'Secondary'
    },
    secondaryButtonActive: {
      type: Boolean,
      default: true
    },
    secondaryButtonDisabled: {
      type: Boolean,
      default: false
    },
    secondaryButtonAnimateLoading: {
      type: Boolean,
      default: false
    },
    primaryButtonText: {
      type: String,
      default: 'Primary'
    },
    primaryButtonActive: {
      type: Boolean,
      default: true
    },
    primaryButtonDisabled: {
      type: Boolean,
      default: false
    },
    primaryButtonAnimateLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
