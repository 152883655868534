var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'component',
    'component__button',
    _vm.text ? 'component__button--text' : '',
    _vm.fullheight ? 'component__button--fullheight' : '',
    _vm.listActive ? 'component__button--listactive' : ''
]},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{class:[
            'column',
            'column--btn',
            `has-text-${_vm.align}`
        ]},[_c(!_vm.disabled ? (_vm.to || _vm.toExternal ? (_vm.to ? 'router-link' : 'a') : 'button') : 'button',{ref:"button",tag:"component",class:_vm.outputClasses,attrs:{"data-testid":_vm.name,"target":_vm.target ? '_blank' : '',"disabled":_vm.disabled,"to":_vm.to,"id":_vm._f("toKebabCase")(_vm.name),"href":_vm.toExternal || _vm.to ? (_vm.toExternal || _vm.$router.resolve(_vm.to).href) : null,"type":_vm.type,"tabindex":0,"title":_vm.title},on:{"mouseenter":function($event){return _vm.$emit('mouseenter')},"mouseleave":function($event){return _vm.$emit('mouseleave')},"click":_vm.clickHandler},nativeOn:{"click":function($event){return _vm.clickHandler.apply(null, arguments)}}},[(_vm.icon)?[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{class:[
                            'is-narrow',
                            'column',
                            'column--icon',
                            `column--icon-align-${_vm.iconAlign}`
                        ]},[_c('div',{class:['component__button_icon', `component__button_icon--${_vm.iconSize}`]},[_c('v-icon',{class:{ 'component__button_icon_before-duplicated' : _vm.iconDuplicate },attrs:{"name":_vm.iconName,"role":"img","aria-label":_vm.iconAriaLabel}}),(_vm.iconDuplicate)?_c('v-icon',{staticClass:"component__button_icon_duplicated",attrs:{"name":_vm.iconName,"role":"img","aria-label":_vm.iconAriaLabel}}):_vm._e()],1)]),(_vm.$slots.default && _vm.$slots.default.length && _vm.$slots.default[0].text)?[_c('div',{class:[
                                'column',
                                'column--label',
                                `column--label-align-${_vm.iconAlign === 'left' ? 'right' : 'left'}`
                            ]},[_c('div',{staticClass:"component__button_label"},[_c('span',[_vm._t("default")],2)])])]:_vm._e()],2)]:[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"component__button_label"},[(_vm.$slots.default && _vm.$slots.default.length && _vm.$slots.default[0].text)?[_c('span',[_vm._t("default")],2)]:_vm._e()],2)])])]],2),(_vm.tooltipActive)?[_c('div',{class:[
                    'component__button_tooltip',
                    _vm.tooltipPosition ? `component__button_tooltip-position--${_vm.tooltipPosition}` : '',
                    _vm.tooltipSize ? `component__button_tooltip-size--${_vm.tooltipSize}` : ''
                ]},[_c('div',{staticClass:"component__button_tooltip_text"},[(_vm.tooltipText)?[_vm._v(_vm._s(_vm.tooltipText))]:_vm._e(),_vm._t("tooltip")],2)])]:_vm._e(),(_vm.hint)?[_c('div',{staticClass:"component__button_hint"},[_vm._v(_vm._s(_vm.hint))])]:_vm._e()],2),(_vm.listActive)?[_c('div',{class:[
                'column',
                'column--list'
            ]},[_c('div',{staticClass:"component__button_list"},[_c('component-button-menu',{attrs:{"icon-name":'chevron-down',"theme":_vm.theme,"buttons":_vm.listItems},on:{"emit-close":_vm.handleCloseListMenu,"emit-open":_vm.handleOpenListMenu,"emit":_vm.handleEmit}})],1)])]:_vm._e(),(_vm.disabled && _vm.disabledHintActive)?[_c('div',{class:['column', 'is-narrow', `column--align-${_vm.disabledHintAlign}`]},[_c('div',{staticClass:"component__button_hint custom-none"},[_c('div',{staticClass:"component__button_hint_action"},[_c('v-icon',{attrs:{"name":'help-circle'}})],1),_c('div',{class:[
                        'component__button_hint_box',
                        _vm.disabledHintPosition ? `component__button_hint_box--${_vm.disabledHintPosition}` : ''
                    ]},[_c('div',{staticClass:"component__button_hint_box_title"},[_vm._v(_vm._s(_vm.disabledHintTitle))]),(_vm.disabledHintText && _vm.disabledHintText.length)?[_c('div',{staticClass:"component__button_hint_box_text"},[_vm._v(" "+_vm._s(_vm.disabledHintText)+" ")])]:_vm._e(),(_vm.disabledHintItems.length)?[_c('ul',{staticClass:"component__button_hint_box_list"},_vm._l((_vm.disabledHintItems),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item)+" ")])}),0)]:_vm._e(),(_vm.disabledHintExtraItems.length)?[_c('div',{staticClass:"component__button_hint_box_title component__button_hint_box_title--extra"},[_vm._v(" "+_vm._s(_vm.disabledHintExtraTitle)+" ")]),_c('ul',{staticClass:"component__button_hint_box_list component__button_hint_box_list--extra"},_vm._l((_vm.disabledHintExtraItems),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item)+" ")])}),0)]:_vm._e()],2)])])]:_vm._e()],2),(_vm.listActive && _vm.listItems && _vm.listItems.length)?void 0:_vm._e(),(_vm.notifyActive)?[_c('pop-notify',{ref:"componentpopnotify",class:`component__button_notify--${_vm.notifyTitle}`,attrs:{"title-text":_vm.notifyTitle,"content-text":_vm.notifyText,"prevent-close":_vm.notifyPreventClose,"cancel-button-text":_vm.notifyCancelText,"custom-button-active":_vm.notifyCustomActive,"custom-button-text":_vm.notifyCustomText,"custom-button-danger":_vm.notifyCustomDanger,"custom-button-animate-loading":_vm.notifyCustomAnimateLoading,"submit-button-active":_vm.notifySubmitActive,"submit-button-text":_vm.notifySubmitText,"submit-button-danger":_vm.notifySubmitDanger,"submit-button-animate-loading":_vm.notifySubmitAnimateLoading,"submit-button-animate-loading-name":_vm.notifySubmitAnimateLoadingName,"select-active":_vm.notifySelectActive,"select-title":_vm.notifySelectTitle,"select-items":_vm.notifySelectItems,"select-hint":_vm.notifySelectHint,"size":_vm.notifySize},on:{"emit-custom":_vm.emitNotifyCustom,"emit-submit":_vm.emitNotifySubmit}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }