import store from '@/store'
import { eventbus } from '@/main'
import router from '@/router'
import { TokenHelper } from '@/TokenHelper'

export function checkResponseUrlValidate (url) {
  url = url ? url.toLowerCase() : ''
  const checkUrl = url.includes('validate')
  eventbus.$emit('stop-sending')
  return checkUrl
}

export function checkResponseUrlIgnored (url) {
  url = url ? url.toLowerCase() : ''
  const checkUrl = (
  url.includes('trainers-check') ||
  url.includes('/api/v2/user/email') ||
  url.includes('/api/v2/user/login') ||
  url.includes('/api/v2/cognito') ||
  url.includes('/api/v2/invitations') ||
  url.includes('/api/v2/feedback') ||
  url.includes('/api/self-registration/resend-code/') ||
  url.includes('/api/book-and-enrol') ||
  url.includes('/api/unit-with-courses') ||
  url.includes('/self-registration/workspace-keys') ||
  url.includes('/api/v2/master-admin') ||
  url.includes('/api/v2/trainer') ||
  url.includes('/api/workspace-group/') ||
  url.includes('/api/v2/user/count-login') ||
  url.match(/api\/event\/[\S]+\/archive/) ||
  url.match(/api\/events\/[\S]+\/reactivate/)
  )
  return checkUrl
}

export function checkResponseNotFound (status) {
  const checkStatus = String(status).includes('404')
  if (checkStatus) {
    store.dispatch('defaultNotificationToastSetPrevent', true)
    router.push({ name: '404' })
  }
}

export function checkResponseNoAccess (message) {
  const checkMessage = String(message).includes('token_domain_error')
  if (checkMessage) {
    store.dispatch('defaultNotificationToastSetPrevent', false)
    router.push({ name: '404' })
  }
}

export function refreshSecondStageToken (response) {
  const currentSecondStageToken = TokenHelper.getToken('secondStageToken')

  if (!currentSecondStageToken) return

  const currentSecondStageTokenPayload = TokenHelper.getPayload(currentSecondStageToken)
  const isCurrentSecondStageTokenFromOldLMS = currentSecondStageTokenPayload.origin === 'subdomain'
  const isCurrentSecondStageTokenFromNewLMS = currentSecondStageTokenPayload.origin === 'workspace'

  const newSecondStageToken = response.headers.access_token

  if (!newSecondStageToken) return

  const newSecondStageTokenPayload = TokenHelper.getPayload(newSecondStageToken)
  const isNewSecondStageTokenFromOldLMS = newSecondStageTokenPayload.origin === 'subdomain'
  const isNewSecondStageTokenFromNewLMS = newSecondStageTokenPayload.origin === 'workspace'

  if (
    (isCurrentSecondStageTokenFromOldLMS && isNewSecondStageTokenFromOldLMS) ||
  (isCurrentSecondStageTokenFromNewLMS && isNewSecondStageTokenFromNewLMS)
  ) {
    store.dispatch('authUserSetSecondStageToken', newSecondStageToken)
  }
}

export function emitStopRequest () {
  eventbus.$emit('stop-sending')
  eventbus.$emit('stop-loading')
  eventbus.$emit('error-loading')
}

export function emitErrorNotification (data) {
  const responseUrl = data.responseUrl
  const responseMessage = data.responseMessage
  const preventErrorsMessageList = ['item_in_use']
  const preventErrorsUrlList = [
    'api/organisation-unit/',
    'api/v2/offer/',
    'api/v2/courses/',
    'api/self-registration/confirm-signup',
    'api/v2/custom-fields',
    'api/feedback-form-templates',
    'api/signup-and-authentication-settings/',
    'api/bookings',
    '/form/apikey',
    'api/v2/bulk-offers'
  ]

  if (!preventErrorsMessageList.includes(responseMessage) && !preventErrorsUrlList.find(obj => responseUrl.includes(obj))) {
    store.dispatch('defaultNotificationToastsAdd', {
      title: 'Error',
      message: responseMessage,
      status: 'error'
    })
  }
}
