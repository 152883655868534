import axios from 'axios'
import api from '@/api'
import { decodeEmail, encodeEmail } from '@/functions'
import { UUID } from '@/UUID'

const data = {
  data: {
    email: {
      content: '',
      createdAt: '',
      id: '',
      cloned: false,
      identifier: '',
      name: '',
      subject: '',
      tags: [],
      active: true,
      title: '',
      fromDefault: false,
      reminderSendAfterDays: null,
      resendReminderCount: null,
      resendReminderPeriod: '',
      triggerEvent: ''
    },
    //
    updateSuccess: false
  },
  default: {
    email: ''
  }
}

const ebsEmail = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getEmail: state => {
      return state.data.email
    },
    getEmailId: state => {
      return state.data.email.id
    },
    getEmailName: state => {
      return state.data.email.name
    },
    getEmailTitle: state => {
      return state.data.email.title
    },
    getEmailDescription: state => {
      return state.data.email.description
    },
    getEmailSubject: state => {
      return state.data.email.subject
    },
    getEmailOwnerId: state => {
      return state.data.email.ownerId
    },
    getEmailContent: state => {
      const identifier = state.data.email.identifier
      const content = state.data.email.content
      if (identifier && content) {
        return decodeEmail(content)
      } else {
        return content
      }
    },
    getEmailCloned: state => {
      return state.data.email.cloned
    },
    getEmailIdentifier: state => {
      return state.data.email.identifier
    },
    getEmailTriggerEvent: state => {
      return state.data.email.triggerEvent
    },
    getEmailTags: state => {
      let tags = state.data.email.tags
      return tags ? tags.map(function (object) {
        return {
          label: object.name,
          value: object.tag
        }
      }) : []
    },
    //
    getEmailUpdateSuccess: state => {
      return state.data.updateSuccess
    },
    getEmailDefaultContent: state => {
      const content = state.default.email.content
      if (content) {
        return decodeEmail(content)
      }
    },
    getEmailStatus: state => {
      return state.data.email.active
    },
    getEmailReminderSendAfterDays: state => {
      return state.data.email.reminderSendAfterDays
    },
    getEmailResendReminderCount: state => {
      return state.data.email.resendReminderCount
    },
    getEmailResendReminderPeriod: state => {
      return state.data.email.resendReminderPeriod
    }
  },
  mutations: {
    GET_EMAIL: (state, payload) => {
      payload.reminderSendAfterDays = payload.reminderSendAfterDays || 0
      payload.resendReminderCount = payload.resendReminderCount || 0
      payload.resendReminderPeriod = payload.resendReminderPeriod || 0
      payload.title = payload.title || ''
      payload.fromDefault = true
      state.data.email = payload
      state.default.email = JSON.parse(JSON.stringify(payload))
    },
    //
    CLEAR_EMAIL: (state) => {
      state.data = JSON.parse(JSON.stringify(data.data))
    },
    RESET_EMAIL (state) {
      state.data.email = JSON.parse(JSON.stringify(state.default.email))
    },
    //
    SET_EMAIL_NAME (state, payload) {
      state.data.email.name = payload
    },
    SET_EMAIL_TITLE (state, payload) {
      state.data.email.title = payload
    },
    SET_EMAIL_SUBJECT (state, payload) {
      state.data.email.subject = payload
    },
    SET_EMAIL_CONTENT (state, payload) {
      state.data.email.content = encodeEmail(payload)
    },
    SET_EMAIL_TAGS (state, payload) {
      state.data.email.tags = payload
    },
    SET_EMAIL_OWNERID (state, payload) {
      state.data.email.ownerId = payload
    },
    SET_EMAIL_FROMDEFAULT (state, payload) {
      state.data.email.fromDefault = payload
    },
    SET_EMAIL_UPDATESUCCESS (state, payload) {
      state.data.updateSuccess = payload
    },
    SET_EMAIL_REMINDERSENDAFTERDAYS (state, payload) {
      state.data.email.reminderSendAfterDays = payload
    },
    SET_EMAIL_RESENDREMINDERCOUNT (state, payload) {
      state.data.email.resendReminderCount = payload
    },
    SET_EMAIL_RESENDREMINDERPERIOD (state, payload) {
      state.data.email.resendReminderPeriod = payload
    }
  },
  actions: {
    getEmail ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let identifier
        let id

        if (UUID.validate(data)) {
          identifier = undefined
          id = data
        } else {
          identifier = data
          id = undefined
        }

        commit('CLEAR_EMAIL')
        return axios({
          method: 'post',
          url: `${api.emailservice}/domain/email`,
          data: {
            identifier,
            id
          }
        }).then(response => {
          commit('GET_EMAIL', response.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getEmailByKey ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_EMAIL')

        return axios({
          method: 'get',
          url: `${api.emailservice}/domain/emails?filters[${data.key}]=${data.value}`
        }).then(response => {
          const emails = response.data.data
          const emailId = data.id
          let selectedEmail

          if (emails && emails.length) {
            if (emailId) {
              selectedEmail = emails.find(email => email.id === emailId) || emails[0]
            } else {
              selectedEmail = emails.find(email => !email.cloned)
            }
          } else {
            selectedEmail = undefined
          }

          commit('GET_EMAIL', selectedEmail)

          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    createEmail ({ commit, state, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
        let context = data || JSON.parse(JSON.stringify(state.data.email))
        let ownerId
        if (isOldLMSToken) {
          ownerId = getters.getAuthUserSecondStageTokenDataSubdomains[0]
        } else {
          ownerId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        }
        if (context.id === context.identifier) {
          context.fromDefault = true
        }
        context.ownerId = ownerId
        commit('SET_EMAIL_UPDATESUCCESS', false)
        return axios({
          method: 'post',
          url: `${api.emailservice}/emails`,
          data: context
        }).then(response => {
          if (context.action !== undefined && context.action === 'statusUpdate') {
            if (context.active === true) {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Notification activated',
                message: `Notification has been successfully activated.`,
                status: 'success'
              })
            } else {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Notification deactivated',
                message: `Notification has been successfully deactivated.`,
                status: 'success'
              })
            }
          } else {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Email created',
              message: `Email has been successfully created.`,
              status: 'success'
            })
          }
          commit('SET_EMAIL_UPDATESUCCESS', true)
          commit('CLEAR_EMAILS')
          resolve(response)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email create',
            message: `Email hasn't been created.`,
            status: 'error'
          })
          commit('SET_EMAIL_UPDATESUCCESS', false)
          reject(error)
        })
      })
    },
    updateEmail ({ commit, state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let context = data || JSON.parse(JSON.stringify(state.data.email))
        const id = JSON.parse(JSON.stringify(context.id))
        context.createdAt = undefined
        context.reminderSendAfterDays = context.reminderSendAfterDays || null
        context.resendReminderCount = context.resendReminderCount || null
        commit('SET_EMAIL_UPDATESUCCESS', false)
        return axios({
          method: 'patch',
          url: `${api.emailservice}/emails/${id}`,
          data: context
        }).then(function (response) {
          if (context.action !== undefined && context.action === 'statusUpdate') {
            if (context.active === true) {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Notification activated',
                message: `Notification has been successfully activated.`,
                status: 'success'
              })
            } else {
              dispatch('defaultNotificationToastsAdd', {
                title: 'Notification deactivated',
                message: `Notification has been successfully deactivated.`,
                status: 'success'
              })
            }
          } else {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Email updated',
              message: `Email has been successfully updated.`,
              status: 'success'
            })
          }
          commit('SET_EMAIL_UPDATESUCCESS', true)
          commit('CLEAR_EMAILS')
          resolve(response.data)
        }).catch(function (error) {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email update',
            message: `Email hasn't been updated.`,
            status: 'error'
          })
          commit('SET_EMAIL_UPDATESUCCESS', false)
          reject(new Error(error))
        })
      })
    },
    cloneEmail ({ commit, state, getters, dispatch }) {
      return new Promise((resolve, reject) => {
        const isOldLMSToken = getters.getAuthUserIsSecondStageTokenOldLMS
        let stateData = JSON.parse(JSON.stringify(state.data.email))
        let ownerId
        let id = stateData.id
        let identifier = stateData.identifier
        let fromDefault
        const content = stateData.content
        const subject = stateData.subject
        const title = stateData.title
        const reminderSendAfterDays = stateData.reminderSendAfterDays
        const resendReminderCount = stateData.resendReminderCount
        const resendReminderPeriod = stateData.resendReminderPeriod

        if (id === identifier) {
          id = undefined
          fromDefault = true
        } else {
          identifier = undefined
          fromDefault = undefined
        }

        if (isOldLMSToken) {
          ownerId = getters.getAuthUserSecondStageTokenDataSubdomains[0]
        } else {
          ownerId = getters.getAuthUserSecondStageTokenDataWorkspaceId
        }

        return axios({
          method: 'post',
          url: `${api.emailservice}/emails/clone`,
          data: {
            id,
            identifier,
            content,
            subject,
            title,
            reminderSendAfterDays,
            resendReminderCount,
            resendReminderPeriod,
            ownerId,
            fromDefault
          }
        }).then(response => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email create',
            message: `Email has been successfully created.`,
            status: 'success'
          })
          commit('CLEAR_EMAILS')
          resolve(response.data)
        }).catch(error => {
          dispatch('defaultNotificationToastsAdd', {
            title: 'Email create',
            message: `Email hasn't been created.`,
            status: 'error'
          })
          commit('SET_EMAIL_UPDATESUCCESS', false)
          reject(error)
        })
      })
    },
    removeEmail ({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const id = data || state.data.email.id
        commit('SET_EMAIL_UPDATESUCCESS', false)

        return axios({
          method: 'delete',
          url: `${api.emailservice}/emails/${id}`
        }).then(function (response) {
          commit('SET_EMAIL_UPDATESUCCESS', true)
          commit('CLEAR_EMAIL')
          commit('CLEAR_EMAILS')
          resolve(response)
        }).catch(function (error) {
          commit('SET_EMAIL_UPDATESUCCESS', false)
          reject(new Error(error))
        })
      })
    },
    checkEmailUsage (ctx, data) {
      return new Promise((resolve, reject) => {
        const id = data

        return axios({
          method: 'get',
          url: `${api.courseCatalog}/v2/catalog/notification-usage/${id}`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(new Error(error))
        })
      })
    },
    //
    clearEmail ({ commit }) {
      commit('CLEAR_EMAIL')
    },
    resetEmail ({ commit }) {
      commit('RESET_EMAIL')
    },
    //
    setEmail ({ commit }, data) {
      commit('GET_EMAIL', data)
    },
    setEmailName ({ commit }, data) {
      commit('SET_EMAIL_NAME', data)
    },
    setEmailTitle ({ commit }, data) {
      commit('SET_EMAIL_TITLE', data)
    },
    setEmailSubject ({ commit }, data) {
      commit('SET_EMAIL_SUBJECT', data)
    },
    setEmailContent ({ commit }, data) {
      commit('SET_EMAIL_CONTENT', data)
    },
    setEmailFromDefault ({ commit }, data) {
      commit('SET_EMAIL_FROMDEFAULT', data)
    },
    setEmailReminderSendAfterDays ({ commit }, data) {
      commit('SET_EMAIL_REMINDERSENDAFTERDAYS', data)
    },
    setEmailResendReminderCount ({ commit }, data) {
      commit('SET_EMAIL_RESENDREMINDERCOUNT', data)
    },
    setEmailResendReminderPeriod ({ commit }, data) {
      commit('SET_EMAIL_RESENDREMINDERPERIOD', data)
    }
  }
}

export default ebsEmail
