var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'form',
    'form__textarea',
    _vm.size.length ? `form__textarea--${_vm.size}` : '',
    _vm.overlay ? `form__textarea--overlay` : '',
    _vm.rows ? `form__textarea--rows` : '',
    _vm.disabled ? 'form__textarea--disabled' : ''
    ]},[(_vm.label)?_c('label',{staticClass:"form__textarea_label",attrs:{"for":_vm._f("toKebabCase")(_vm.name)}},[_vm._v(_vm._s(_vm._f("toCapitalize")(_vm.label))),(_vm.required)?_c('span',{staticClass:"asterisk"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newValue),expression:"newValue"},{name:"validate",rawName:"v-validate",value:(_vm.vValidate),expression:"vValidate"}],staticClass:"form__textarea_input",attrs:{"rows":_vm.rows,"id":_vm._f("toKebabCase")(_vm.name),"name":_vm._f("toKebabCase")(_vm.name),"placeholder":_vm.placeholder,"disabled":_vm.disabled,"data-vv-as":_vm.dataVvAs ? _vm.dataVvAs : _vm.label.toLowerCase()},domProps:{"value":(_vm.newValue)},on:{"focus":_vm.emitFocus,"blur":_vm.emitBlur,"input":function($event){if($event.target.composing)return;_vm.newValue=$event.target.value}}}),(_vm.errors.first(_vm.toKebabCase(_vm.name)))?_c('span',{staticClass:"form__error form__textarea_error"},[_vm._v(_vm._s(_vm.errors.first(_vm.toKebabCase(_vm.name))))]):_vm._e(),(_vm.$slots.default)?[_c('div',{staticClass:"form__textarea_hint"},[(_vm.hintButton)?[_c('component-button',{attrs:{"name":'form-textarea-hint-button',"text":true,"size":'tiny'},on:{"click":_vm.emitHint}},[_vm._t("default")],2)]:[_vm._t("default")]],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }