<template>
    <div
        :class="[
        'form',
        'form__textarea',
        size.length ? `form__textarea--${size}` : '',
        overlay ? `form__textarea--overlay` : '',
        rows ? `form__textarea--rows` : '',
        disabled ? 'form__textarea--disabled' : ''
        ]"
    >
        <label
            v-if="label"
            :for="name | toKebabCase"
            class="form__textarea_label"
        >{{ label | toCapitalize }}<span class="asterisk" v-if="required">*</span></label>
        <textarea
            :rows="rows"
            class="form__textarea_input"
            :id="name | toKebabCase"
            :name="name | toKebabCase"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="newValue"
            v-validate="vValidate"
            :data-vv-as="dataVvAs ? dataVvAs : label.toLowerCase()"
            @focus="emitFocus"
            @blur="emitBlur"
        ></textarea>
        <span
            class="form__error form__textarea_error"
            v-if="errors.first(toKebabCase(name))"
        >{{ errors.first(toKebabCase(name)) }}</span>
        <template v-if="$slots.default">
            <div class="form__textarea_hint">
                <template v-if="hintButton">
                    <component-button
                        :name="'form-textarea-hint-button'"
                        :text="true"
                        :size="'tiny'"
                        @click="emitHint"
                    >
                        <slot></slot>
                    </component-button>
                </template>
                <template v-else>
                    <slot></slot>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { toKebabCase } from '@/functions'

export default {
  name: 'form-textarea',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    size: {
      type: String,
      default: ''
    },
    overlay: {
      type: Boolean,
      default: false
    },
    //
    dataVvAs: {
      type: String,
      default: ''
    },
    vValidate: {
      default: ''
    },
    hintButton: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number
    }
  },
  computed: {
    newValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    emitFocus () {
      const field = this.$validator.errors.items.find(obj => obj.field === toKebabCase(this.name))
      this.$validator.pause()
      if (field) {
        this.$validator.reset(field)
      }
      this.$emit('emit-focus')
    },
    emitBlur () {
      const field = this.$validator.errors.items.find(obj => obj.field === toKebabCase(this.name))
      this.$validator.resume()
      if (field) {
        this.$validator.reset(field)
      }
      this.$emit('emit-blur')
    },
    emitHint: function () {
      this.$emit('emit-hint')
    }
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
@import "~@/assets/scss/components/default/forms/formtextarea";
</style>
