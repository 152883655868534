import axios from 'axios'
import api from '@/api'

const data = {
  data: '',
  default: ''
}

const lmsSuperadminOrgUnit = {
  state: JSON.parse(JSON.stringify(data)),
  getters: {
    getLmsSuperadminOrgUnit: state => {
      return state.data
    }
  },
  mutations: {
    LMS_SUPERADMIN_ORGUNIT_SET (state, payload) {
      state.data = payload
    },
    LMS_SUPERADMIN_ORGUNIT_CLEAR (state) {
      state.custom = JSON.parse(JSON.stringify(data.custom))
    }
  },
  actions: {
    lmsSuperadminOrgUnitUpdate ({ getters }, data) {
      return new Promise((resolve, reject) => {
        let name = data.name
        let parentId = data.parentId
        let unitId = data.id
        let workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        let requestMethod = unitId ? 'patch' : 'post'
        let requestUrl = unitId ? `/organisation-unit/${ unitId }` : `/organisation-unit`
        let bodyData = {}

        bodyData.workspaceId = workspaceId
        if (!unitId) {
          bodyData.parentId = parentId
        }
        if (name) {
          bodyData.name = name
        }

        axios({
          method: requestMethod,
          url: `${ api.subscriptionmanager }${ requestUrl }`,
          data: bodyData
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitMoveUsers (ctx, data) {
      return new Promise((resolve, reject) => {
        const targetUnitId = data.targetUnitId
        const workspaceId = data.workspaceId
        const profiles = data.profiles
        const suggestedCourses = data.suggestedCourses

        axios({
          method: 'POST',
          url: `${ api.sso }/v2/move-organisation-unit-users`,
          data: {
            targetUnitId,
            workspaceId,
            profiles,
            suggestedCourses
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitRemoveUsers (ctx, data) {
      return new Promise((resolve, reject) => {
        const unitId = data.unitId
        const workspaceId = data.workspaceId
        const userProfileId = data.userProfileId

        axios({
          method: 'POST',
          url: `${ api.sso }/v2/remove-organisation-unit-user`,
          data: {
            unitId,
            workspaceId,
            userProfileId
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitAddManager (ctx, data) {
      return new Promise((resolve, reject) => {
        const unitId = data.unitId
        const profileId = data.profileId

        axios({
          method: 'POST',
          url: `${ api.subscriptionmanager }/organisation-unit/${ unitId }/managers/${ profileId }`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitRemoveManager (ctx, data) {
      return new Promise((resolve, reject) => {
        const unitId = data.unitId
        const profileId = data.profileId

        axios({
          method: 'DELETE',
          url: `${ api.subscriptionmanager }/organisation-unit/${ unitId }/managers/${ profileId }`
        }).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitRemove ({ commit, getters, dispatch }, data) {
      return new Promise((resolve, reject) => {
        const unitId = data.id
        const index = data.index
        let workspaceId = data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId

        if (!unitId) {
          commit('LMS_SUPERADMIN_ORGUNITS_REMOVE', index)
          resolve()
        } else {
          axios({
            method: 'delete',
            url: `${ api.subscriptionmanager }/organisation-unit/${ unitId }`,
            data: {
              workspaceId
            }
          }).then(response => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Organisation unit remove',
              message: 'Organisation unit has been successfully removed.',
              status: 'success'
            })
            resolve(response)
          }).catch(error => {
            dispatch('defaultNotificationToastsAdd', {
              title: 'Organisation unit remove',
              message: `Organisation unit hasn't been removed because it has assigned users.`,
              status: 'error'
            })
            reject(error)
          })
        }
      })
    },
    lmsSuperadminOrgUnitMove ({ state, getters }, data) {
      return new Promise((resolve, reject) => {
        const parentId = data.to
        const id = data.id
        let workspaceId = state.data.workspaceId || getters.getAuthUserSecondStageTokenDataWorkspaceId
        axios({
          method: 'post',
          url: `${ api.subscriptionmanager }/organisation-unit/reorder`,
          data: {
            id,
            parentId,
            workspaceId
          }
        }).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    lmsSuperadminOrgUnitClear ({ commit }) {
      commit('LMS_SUPERADMIN_ORGUNIT_CLEAR_DATA')
    }
  }
}

export default lmsSuperadminOrgUnit
