<template>
    <div :class="[
        'form',
        'form__text',
        readOnly ? 'form__text--readonly' : '',
        showOnlyValidateErrorMessage ? 'form__text--onlyvalidateerrormessage' : ''
    ]">
        <label
            v-if="label"
            :for="name | toKebabCase"
            :class="[
                'form__text_title'
            ]"
        >{{label | toCapitalize}}<span class="asterisk" v-if="required">*</span></label>
        <template v-if="$slots.default && hintPositionTop">
            <div class="form__text_hint form__text_hint--top">
                <slot></slot>
            </div>
        </template>
        <div class="form__text_wrapper">
            <template v-if="readOnly">
                <div class="columns is-vcentered">
                    <div class="column form__text_wrapper--columnvalue is-narrow">
                        <div class="form__text_wrapper_value">{{ newValue }}</div>
                    </div>
                    <template v-if="icon">
                        <div class="column">
                            <component-button
                                :class="'form__text_wrapper_icon_copy'"
                                :name="'form-text-wrapper-icon-copy'"
                                :align="'left'"
                                :icon="true"
                                :icon-name="iconName"
                                :icon-raw="true"
                                :tooltip-active="true"
                                :tooltip-text="`Copy ${iconCopyName}`"
                                :theme="''"
                                @click="handleCopyToClipboard"
                            ></component-button>
                        </div>
                    </template>
                </div>
            </template>
            <template v-else>
                <input
                    ref="formtext"
                    class="form__text_wrapper_input"
                    :type="password ? 'password' : 'text'"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    :id="name | toKebabCase"
                    :name="name | toKebabCase"
                    :data-vv-as="dataVvAs ? dataVvAs : name"
                    v-model="newValue"
                    :value="disabled && disabledHidden ? '' : newValue"
                    v-validate="vValidate"
                    @focus="handleFocus"
                    @keyup.enter="emitEnter"
                    @click="emitFocus"
                    @blur="emitBlur"
                >
                <template v-if="password">
                    <div
                        class="form__text_wrapper_password"
                        @click="switchTypePassword"
                    >
                        <template v-if="inputType === 'text'">
                            <v-icon role="img" aria-label="Show/hide password" :name="'eye'"></v-icon>
                        </template>
                        <template v-if="inputType === 'password'">
                            <v-icon role="img" aria-label="Show/hide password" :name="'eye-off'"></v-icon>
                        </template>
                    </div>
                </template>
                <template v-if="icon">
                    <div class="form__text_wrapper_icon">
                        <template v-if="iconCopyable">
                            <component-button
                                :class="'form__text_wrapper_icon_copy'"
                                :name="'form-text-wrapper-icon-copy'"
                                :icon="true"
                                :icon-name="'link'"
                                :icon-raw="true"
                                :theme="''"
                                @click="handleCopyToClipboard"
                            ></component-button>
                        </template>
                        <template v-else>
                            <v-icon :name="iconName"></v-icon>
                        </template>
                    </div>
                </template>
            </template>
        </div>
        <span
            class="form__error form__text_error"
            v-if="errors.first(toKebabCase(name))"
        >{{ vValidateCustomError || errors.first(toKebabCase(name)) }}</span>
        <template v-if="$slots.default && !hintPositionTop">
            <div class="form__text_hint">
                <template v-if="hintButton">
                    <component-button
                        :name="'form-text-hint-button'"
                        :text="true"
                        :size="'tiny'"
                        @click="handleHint"
                    >
                        <slot></slot>
                    </component-button>
                </template>
                <template v-else>
                    <slot></slot>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import { toKebabCase, toCapitalize } from '@/functions'

export default {
  name: 'form-text',
  components: {
    ComponentButton: () => import('@/components/default/shared/ComponentButton')
  },
  data () {
    return {
      inputType: 'text'
    }
  },
  props: {
    showOnlyValidateErrorMessage: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    prevent: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true,
      default: 'Label'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledHidden: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number]
    },
    //
    dataVvAs: {
      type: String,
      default: ''
    },
    vValidateCustomError: {
      type: String
    },
    vValidate: {
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    iconCopyable: {
      type: Boolean,
      default: false
    },
    iconCopyName: {
      type: String,
      default: 'value'
    },
    iconName: {
      type: String
    },
    password: {
      type: Boolean,
      default: false
    },
    hintButton: {
      type: Boolean,
      default: false
    },
    hintButtonCopy: {
      type: Boolean,
      default: false
    },
    hintPositionTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    newValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    handleFocus () {
      if (this.prevent) {
        this.$refs.formtext.blur()
      }
    },
    switchTypePassword () {
      const input = this.$refs.formtext
      const attr = input.getAttribute('type')

      if (attr === 'password') {
        this.inputType = 'password'
        input.setAttribute('type', 'text')
      } else {
        this.inputType = 'text'
        input.setAttribute('type', 'password')
      }
    },
    emitEnter () {
      this.$emit('emit-enter')
    },
    emitHint: function () {
      this.$emit('emit-hint')
    },
    emitFocus () {
      const field = this.$validator.errors.items.find(obj => obj.field === toKebabCase(this.name))
      if (field) {
        this.$validator.reset(field)
      }
      this.$emit('emit-focus')
    },
    emitBlur () {
      this.$emit('emit-blur')
    },
    handleHint () {
      if (this.hintButtonCopy) {
        this.handleCopyToClipboard()
      } else {
        this.emitHint()
      }
    },
    handleCopyToClipboard () {
      var input = document.createElement('input')
      input.setAttribute('value', this.value)
      document.body.appendChild(input)
      input.select()
      var result = document.execCommand('copy')
      document.body.removeChild(input)

      this.$store.dispatch('defaultNotificationToastsAdd', {
        title: `${toCapitalize(this.iconCopyName)} copy`,
        message: `${toCapitalize(this.iconCopyName)} has been successfully copied.`,
        status: 'success'
      })

      return result
    }
  },
  inject: ['$validator']
}
</script>

<style lang="scss">
    @import "~@/assets/scss/components/default/forms/formtext";
</style>
